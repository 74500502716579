import { FC, ReactNode, useEffect, useState } from 'react';
import { Typography, Collapse, Space } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from '../appointmentDetailsSidebar.module.scss';

const { Text } = Typography;

interface Props {
  children: ReactNode;
  title: string;
  defaultOpen?: boolean;
  action?: ReactNode;
  style?: object;
  titleIcon?: ReactNode;
}

const SectionCollapse: FC<Props> = ({
  children,
  title,
  defaultOpen,
  action,
  style,
  titleIcon = null,
}) => {
  const [isActive, setIsActive] = useState<boolean>(!!defaultOpen);
  useEffect(() => {
    if (defaultOpen) {
      setIsActive(true);
    }
  }, [defaultOpen]);

  return (
    <Collapse
      ghost
      collapsible="header"
      style={{ marginTop: '24px', width: '100%' }}
      className={styles.collapse}
      activeKey={isActive ? 'appointments-drawer-panel-1' : ''}
      defaultActiveKey={defaultOpen ? 'appointments-drawer-panel-1' : ''}
      onChange={() => setIsActive(!isActive)}
      items={[
        {
          key: 'appointments-drawer-panel-1',
          showArrow: false,
          label: (
            <Space className={styles.spacePanel} style={style}>
              <Space>
                {!!titleIcon && titleIcon}
                <Text className={styles.panelHeader} style={style}>
                  {title}
                </Text>
              </Space>
              <DownOutlined
                style={{ marginRight: '20px' }}
                rotate={isActive ? 180 : 0}
              />
            </Space>
          ),
          extra: action,
          children: children,
        },
      ]}
    />
  );
};

export default SectionCollapse;
