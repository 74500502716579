import { FC, ReactNode, useState } from 'react';
import {
  Row,
  Col,
  Space,
  Button,
  Typography,
  Checkbox,
  Select,
  Tag,
} from 'antd';
import { animated, useSpring } from 'react-spring';
import { UpOutlined } from '@ant-design/icons';
import translation from '../../i18n/translation';
import { useIntl } from 'react-intl';
const { Text } = Typography;

interface FilterRowProps {
  type: string;
  selectedFilters: string[];
  loading: boolean;
  onChangeFilter: (type: IOnChangeFilterType) => void;
  count?: number;
}

export interface IOnChangeFilterType {
  value?: string;
  values?: string[];
  options?: string[];
  ignoreDispatch?: boolean;
}

export const FilterRow: FC<FilterRowProps> = ({
  type,
  selectedFilters,
  loading,
  onChangeFilter,
  count,
}) => (
  <Row>
    <Checkbox
      disabled={loading}
      checked={selectedFilters.includes(type)}
      onChange={() => onChangeFilter({ value: type })}
    >
      <Text style={{ whiteSpace: 'nowrap' }}>
        {translation(type)}
        &nbsp;({count})
      </Text>
    </Checkbox>
  </Row>
);

interface FilterDropdownProps {
  type: string;
  filterTree: any;
  selectedFilters: string[];
  onChangeFilter: (type: IOnChangeFilterType) => void;
}

export const FilterDropdown: FC<FilterDropdownProps> = ({
  filterTree,
  type,
  selectedFilters,
  onChangeFilter,
}) => {
  const { Option } = Select;
  const intl = useIntl();
  const filterKeys = Object.keys(filterTree?.[type]).filter(
    (item: string) => item !== 'dropdown'
  );
  const selectedValue =
    filterKeys.filter((item: string) => selectedFilters.includes(item)) ?? [];
  const onSelect = (newValue: string[]) => {
    onChangeFilter({ values: newValue, options: filterKeys });
  };

  const tagRender = (props: any) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
      >
        {translation(value)}
      </Tag>
    );
  };

  return (
    <Select
      mode="multiple"
      allowClear
      onChange={onSelect}
      value={selectedValue}
      popupMatchSelectWidth={false}
      maxTagCount={3}
      tagRender={tagRender}
      style={{ minWidth: '100px' }}
      placeholder={intl.formatMessage({ id: 'all' })}
    >
      {filterKeys?.map((key: string) => (
        <Option key={`filter-dropdown-key-${key}`} value={key}>
          <Text style={{ whiteSpace: 'nowrap' }}>
            {translation(key)}
            &nbsp;({filterTree?.[type]?.[key].filterIds?.length})
          </Text>
        </Option>
      ))}
    </Select>
  );
};

interface FilterColumnsProps {
  type: any;
  filterTree: any;
  selectedFilters: string[];
  loading: boolean;
  onChangeFilter: any;
  flexDirection: 'horizontal' | 'vertical';
  customHeader?: { [key: string]: ReactNode };
  size?: number;
}

export const FilterColumns: FC<FilterColumnsProps> = ({
  type,
  selectedFilters,
  onChangeFilter,
  flexDirection,
  filterTree,
  customHeader,
  size,
  loading,
}) => {
  const filterKeys = Object.keys(filterTree?.[type]);

  return (
    <Space direction={flexDirection} size={size}>
      {customHeader && customHeader[type] ? (
        customHeader[type]
      ) : (
        <Row key={`filter-row-head-${type}`}>
          <Col>
            <Text strong>{translation(type)}</Text>
          </Col>
        </Row>
      )}
      {filterTree?.[type].dropdown ? (
        <FilterDropdown
          key={`filter-dropdown-${type}`}
          filterTree={filterTree}
          type={type}
          selectedFilters={selectedFilters}
          onChangeFilter={onChangeFilter}
        />
      ) : (
        filterKeys?.map((key: string) => (
          <FilterRow
            key={`filter-row-${key}`}
            type={key}
            selectedFilters={selectedFilters}
            loading={loading}
            onChangeFilter={onChangeFilter}
            count={filterTree?.[type]?.[key].filterIds?.length}
          />
        ))
      )}
    </Space>
  );
};

interface Props {
  filterTree: object;
  selectedFilters: string[];
  ignoreFilters: string[];
  collapsedFilterKey?: string;
  onChangeFilter: (type: IOnChangeFilterType) => void;
  loading: boolean;
  customHeader?: { [key: string]: ReactNode };
}

export const FiltersSectionCollapsible: FC<Props> = ({
  filterTree,
  selectedFilters,
  ignoreFilters,
  collapsedFilterKey,
  onChangeFilter,
  loading,
  customHeader,
}) => {
  const [isFilterCollapsed, setIsFilterCollapsed] = useState<boolean>(false);
  const toggleCollapseFilter = () => setIsFilterCollapsed(!isFilterCollapsed);
  const springConfig = { duration: 100 };

  const filterButtonTransition = useSpring({
    config: springConfig,
    delay: isFilterCollapsed ? 200 : 0,
    rotateZ: isFilterCollapsed ? 90 : 0,
    height: 24,
  });

  const collapseInConfig = [
    {
      opacity: isFilterCollapsed ? 0 : 1,
    },
    {
      height: isFilterCollapsed ? 0 : 120,
    },
  ];

  const collapseOutConfig = [
    {
      height: isFilterCollapsed ? 0 : 120,
    },
    {
      opacity: isFilterCollapsed ? 0 : 1,
    },
  ];

  const openFiltersTransition = useSpring({
    config: springConfig,
    from: { opacity: 0 },
    to: isFilterCollapsed ? collapseInConfig : collapseOutConfig,
  });

  const closedFiltersTransition = useSpring({
    config: springConfig,
    delay: isFilterCollapsed ? 150 : 0,
    from: { opacity: 0 },
    to: {
      opacity: isFilterCollapsed ? 1 : 0,
      height: isFilterCollapsed ? 24 : 0,
      width: isFilterCollapsed ? '100%' : '0%',
    },
  });

  return (
    <>
      <animated.div style={filterButtonTransition}>
        <Button
          type="primary"
          shape="circle"
          icon={<UpOutlined />}
          size={'small'}
          onClick={toggleCollapseFilter}
        />
      </animated.div>
      <Row align="top" style={{ overflow: 'auto', paddingBottom: '16px' }}>
        <animated.div style={closedFiltersTransition}>
          <div
            style={{
              display: 'inline-table',
              width: '100%',
            }}
          >
            <Col key={`filter-header-collapse-${collapsedFilterKey}`}>
              <FilterColumns
                type={collapsedFilterKey}
                filterTree={filterTree}
                selectedFilters={selectedFilters}
                onChangeFilter={onChangeFilter}
                flexDirection="horizontal"
                loading={loading}
                customHeader={customHeader}
                size={50}
              />
            </Col>
          </div>
        </animated.div>

        <animated.div style={openFiltersTransition}>
          <Col>
            <FiltersSection
              filterTree={filterTree}
              selectedFilters={selectedFilters}
              ignoreFilters={ignoreFilters}
              onChangeFilter={onChangeFilter}
              loading={loading}
              customHeader={customHeader}
            />
          </Col>
        </animated.div>
      </Row>
    </>
  );
};

const FiltersSection: FC<Props> = ({
  filterTree,
  selectedFilters,
  ignoreFilters,
  onChangeFilter,
  loading,
  customHeader,
}) => {
  return (
    <Space
      direction="horizontal"
      align="start"
      size={50}
      style={{ whiteSpace: 'nowrap' }}
    >
      {Object.keys(filterTree).map((filterHeadings: any) =>
        !ignoreFilters.includes(filterHeadings) ? (
          <Col key={`filter-header-${filterHeadings}`}>
            <Space direction="vertical">
              <FilterColumns
                type={filterHeadings}
                filterTree={filterTree}
                selectedFilters={selectedFilters}
                onChangeFilter={onChangeFilter}
                flexDirection="vertical"
                customHeader={customHeader}
                loading={loading}
              />
            </Space>
          </Col>
        ) : null
      )}
    </Space>
  );
};

export default FiltersSection;
