import { FC, ReactNode, useEffect, useState } from 'react';
import { Typography, Collapse } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import styles from '../detailDrawer.module.scss';

const { Text } = Typography;

interface Props {
  children: ReactNode;
  title: string;
  defaultOpen?: boolean;
  action?: ReactNode;
  style?: object;
}

const SectionCollapse: FC<Props> = ({
  children,
  title,
  defaultOpen,
  action,
  style,
}) => {
  const [isActive, setIsActive] = useState<boolean>(
    defaultOpen ? defaultOpen : false
  );
  useEffect(() => {
    if (defaultOpen) {
      setIsActive(true);
    }
  }, [defaultOpen]);

  return (
    <Collapse
      ghost
      collapsible="header"
      style={style}
      className={styles.collapse}
      activeKey={isActive ? 'drawer-panel-1' : ''}
      defaultActiveKey={defaultOpen ? 'drawer-panel-1' : ''}
      onChange={() => setIsActive(!isActive)}
      items={[
        {
          key: 'drawer-panel-1',
          extra: action,
          showArrow: false,
          label: (
            <>
              <Text className={styles.panelHeader}>{title}</Text>
              <DownOutlined rotate={isActive ? 180 : 0} />
            </>
          ),
          children: children,
        },
      ]}
    />
  );
};

export default SectionCollapse;
