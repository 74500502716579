import React, {
  useState,
  useRef,
  useEffect,
  Dispatch,
  SetStateAction,
} from 'react';
import { AnyAction } from 'redux';
import {
  Button,
  Input,
  Modal,
  Select,
  Badge,
  Typography,
  Tag,
  Divider,
  Alert,
  Space,
  Spin,
} from 'antd';
import { useIntl } from 'react-intl';
import {
  CloseOutlined,
  CheckOutlined,
  CommentOutlined,
  ReloadOutlined,
  CloseCircleTwoTone,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import Comment from './comment';
import styles from './comments.module.scss';
import {
  IComment,
  IReasonCode,
  IReasonCodeOccurance,
  IStatus,
  IWorkTypeAnswers,
  NotificationType,
  ReasonCodeType,
} from '../../constants/types';
import WorkTypeAction from '../../pages/formInstance/actions';
import translation from '../../i18n/translation';
import { RootState, store } from '../../store';
import { showNotificationMessage } from '../../utils/notification';
import ImageEdit from '../imageSelector/imageEdit';
import ApiRepository from '../../services/api/apiRepository';
import AccessControl from '../accessControl';
import { IFeatures } from '../../store/rolesAndPrivileges';
import { PlaySvg } from '../icons/playIcon';

interface IComments {
  comments?: IComment[];
  isActiveSubmission: boolean;
  answerSnapshot?: IWorkTypeAnswers;
  commentImageList: any[];
  onSetCommentImageList: (arg0: string[]) => void;
  onRemoveImageFromList: (arg0: string) => void;
  sendComment: (
    id: string,
    comment: string,
    approved: boolean,
    appointmentId: string,
    answerSnapshotId: string,
    reasonCodeData: IReasonCodeOccurance
  ) => (dispatch: (arg0: AnyAction) => void) => Promise<void>;
  handleGoToNextStep: (arg0: number | undefined) => void;
  setImageSubmitSuccess: any;
  showSteps: boolean;
  setNumberOfSteps: Dispatch<SetStateAction<2 | 3>>;
  fieldId: string;
  fieldStatus: string;
  disableActions: boolean;
  clipboardData: {
    customerName: string;
    customerAddress: string;
    workType: string;
    field: string;
    appointmentPage: string;
    workTypePage: string;
  };
  appointmentId: string;
  formId: string;
  formFieldId: string;
  approvalType: string;
  reasonCodes: IReasonCode[];
  appointment: any;
  workType: {
    id: string;
    name: string;
    description: string;
  };
  questions: boolean;
  hasApprovalRights: boolean;
}

const Comments: React.FC<IComments> = ({
  comments = [],
  answerSnapshot,
  fieldId,
  disableActions = false,
  clipboardData,
  appointmentId,
  reasonCodes,
  formId,
  appointment,
  formFieldId,
  approvalType,
  workType,
  fieldStatus,
  questions,
  isActiveSubmission,
  commentImageList,
  onSetCommentImageList,
  onRemoveImageFromList,
  handleGoToNextStep,
  setImageSubmitSuccess,
  showSteps,
  setNumberOfSteps,
  hasApprovalRights,
}) => {
  const { TextArea } = Input;
  const { Option } = Select;
  const { Text } = Typography;
  const dispatch = useDispatch();
  const intl = useIntl();
  const workTypeLoadingState = useSelector(
    (state: RootState) => state.workType.loading
  );
  const privileges = store.getState().authentication?.privileges!;
  const [comment, setComment] = useState<string>('');
  const [commentsToShow, setCommentsToShow] = useState<IComment[]>([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isModalConfirmationVisible, setIsConfirmationModalVisible] = useState(
    false
  );
  const [selectedReasonCodes, setSelectedReasonCodes] = useState<IReasonCode[]>(
    []
  );
  const [selectedReasonText, setSelectedReasonText] = useState<String>('');
  const [selectedImageUrl, setSelectedImageUrl] = useState<string>('');

  const [isApproverMode, setApproverMode] = useState(false);
  const [isCommentDisabled, setCommentDisabled] = useState(false);
  const [isOverwritingApproval, setIsOverwritingApproval] = useState(false);
  const [isProcessingApi, setIsProcessingApi] = useState(false);
  const [prevFieldId, setPrevFieldId] = useState('');

  const [isDenyModalVisible, setDenyModalVisible] = useState(false);
  const [isReopenModalVisible, setReopenModalVisible] = useState(false);

  const loginUserName = store.getState().authentication.loginUser?.username;

  const listEnd = useRef<null | HTMLElement>(null);

  useEffect(() => {
    let approverMode = false,
      disabledComment = false,
      isAlreadyOverwritingApproval =
        isApproverMode && isOverwritingApproval && prevFieldId === fieldId;
    setComment(comment && prevFieldId === fieldId ? comment : '');
    setSelectedReasonText(
      selectedReasonText && prevFieldId === fieldId ? selectedReasonText : ''
    );
    setSelectedReasonCodes(
      selectedReasonCodes &&
        selectedReasonCodes.length &&
        prevFieldId === fieldId
        ? selectedReasonCodes
        : []
    );
    const sortedComments = [...comments].sort((a: IComment, b: IComment) => {
      return (
        new Date(a!.timestamp!).getTime() - new Date(b!.timestamp!).getTime()
      );
    });

    if (
      sortedComments &&
      sortedComments.length > 0 &&
      approvalType !== 'SingleApprover'
    ) {
      let latestReviewComment = sortedComments[sortedComments.length - 1];
      approverMode = latestReviewComment.author.username !== loginUserName;
      disabledComment = latestReviewComment.author.username === loginUserName;
    } else if (
      sortedComments &&
      sortedComments.length === 0 &&
      answerSnapshot?.status === IStatus.inReview
    ) {
      approverMode = true;
    }

    setIsProcessingApi(false);
    setCommentsToShow(sortedComments);
    setApproverMode(approverMode);
    setIsOverwritingApproval(isAlreadyOverwritingApproval);
    setCommentDisabled(disabledComment);
    setPrevFieldId(fieldId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [comments, approvalType, loginUserName, answerSnapshot]);

  const handleChange = (ids: any) => {
    let selectedCodes: IReasonCode[] = reasonCodes.filter(
      (code: IReasonCode) => ids.indexOf(code.id) > -1
    );

    let reasonTextList = selectedCodes.reduce(
      (accumulator, code, index) =>
        `${accumulator}${code.code}: ${code.description}\n`,
      ''
    );

    setSelectedReasonCodes(selectedCodes);
    setSelectedReasonText(reasonTextList);
  };

  const clipboardText =
    'Kunde:\n' +
    `${clipboardData.customerName} \n${clipboardData.customerAddress} \n${clipboardData.appointmentPage} \n \n` +
    `${clipboardData.workType} \n${clipboardData.field} \n${clipboardData.workTypePage}`;

  const tagRender = (props: any) => {
    const { value, closable, onClose } = props;
    const onPreventMouseDown: any = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
    };
    let reason: IReasonCode | undefined = reasonCodes.find(
      (item) => item.id === value
    );
    return (
      <Tag
        onClick={onClose}
        color={reason!.severity === ReasonCodeType.warning ? 'orange' : 'red'}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{ marginRight: 3, cursor: 'pointer' }}
      >
        {reason!.code}
      </Tag>
    );
  };

  const getPreviewComment = () => {
    return `${comment}${
      comment && selectedReasonText ? ' \n\nPrüfpunkte\n' : ''
    }${selectedReasonText}`;
  };

  const renderPreviewComment = () => {
    return (
      <div className={styles.previewCommentText}>
        <Text>{getPreviewComment()}</Text>
      </div>
    );
  };

  const onUpdateCommentImageList = (newBlobUrl: string | undefined) => {
    const newArr = commentImageList.filter((item) => item !== selectedImageUrl);
    if (newBlobUrl) {
      onSetCommentImageList([newBlobUrl, ...newArr]);
    } else {
      onSetCommentImageList([...newArr]);
    }
    setSelectedImageUrl('');
  };

  const uploadCommentImages = async (imageBlobs: string[]) => {
    if (imageBlobs.length > 0) {
      dispatch(WorkTypeAction.setSubmitLoadingDetails(true));
      const readyImageList: string[] = [];
      let formData = new FormData();

      for (const image of imageBlobs) {
        let blob = await fetch(image).then((r) => r.blob());
        var blobUrl = image.split('/');
        var blobName = `${blobUrl[blobUrl.length - 1]}`.split('.')[0];
        var blobExtention = blob.type.split('/').pop();
        formData.append('file', blob, `${blobName}.${blobExtention}`);
      }

      try {
        const result: any = await ApiRepository.uploadFile(
          appointment.externalCaseId,
          formData
        );
        result.response.forEach((file: any) => {
          readyImageList.push(file.url);
        });
        onSetCommentImageList([]);
        return readyImageList;
      } catch (error: any) {
        showNotificationMessage({
          message: intl.formatMessage({
            id: error.errorKey ?? 'error_upload_images',
          }),
          error: error,
        });
        dispatch(WorkTypeAction.setSubmitLoadingDetails(false));
        return;
      }
    } else {
      return [];
    }
  };

  const onSubmitComment = async (
    fieldId: string,
    comment: string,
    approved: boolean,
    appointmentId: string
  ) => {
    const uploads = await uploadCommentImages(commentImageList);
    if (!uploads) {
      return;
    }

    let reasonCodeData: IReasonCodeOccurance = {
      reasonCodeDefinitionIds: selectedReasonCodes.map(
        (rc: IReasonCode) => rc.id
      ),
      comment,
      formId,
      formFieldId,
      appointment: {
        caseId: appointment.caseId,
        appointmentId: appointment.id,
        appoitmentType: appointment.appointmentType.id,
        customer: appointment.customerName,
        team: appointment.team,
        dateOfAppointment: appointment.appointmentDate,
      },
      workTypeId: workType.id,
      answerSnapshotId: answerSnapshot?.id ?? '',
      answerId: fieldId,
    };
    dispatch(
      WorkTypeAction.sendComment(
        fieldId,
        comment,
        approved,
        appointmentId,
        answerSnapshot?.id ?? '',
        reasonCodeData,
        isOverwritingApproval,
        uploads
      )
    );
    setComment('');
    setSelectedReasonText('');
    setSelectedReasonCodes([]);
  };

  const onSubmitReply = async () => {
    const uploads = await uploadCommentImages(commentImageList);
    if (!uploads) {
      return;
    }
    dispatch(
      WorkTypeAction.sendReply(
        fieldId,
        getPreviewComment(),
        appointmentId,
        answerSnapshot?.id ?? '',
        uploads
      )
    );
    setComment('');
  };

  const renderCommentImageThumbnails = () => (
    <>
      {commentImageList?.length > 0 && (
        <Space style={{ marginTop: '16px' }} direction="vertical">
          <Text type="secondary" italic>
            * {translation('max_five_images_for_comment')}
          </Text>
          <Space>
            {commentImageList.map((url, index) => (
              <Space key={index} style={{ alignItems: 'start' }}>
                <img
                  src={url}
                  alt="thumbnail"
                  className={styles.commentImage}
                  onClick={() => setSelectedImageUrl(url)}
                />
                <CloseCircleTwoTone
                  twoToneColor={'#4c36ff'}
                  onClick={() => onRemoveImageFromList(url)}
                  style={{
                    margin: '4px 0 0 -32px',
                    zIndex: 2,
                    fontSize: '20px',
                  }}
                />
              </Space>
            ))}
          </Space>
        </Space>
      )}
    </>
  );
  const renderCommentSection = () => (
    <>
      {!isCommentDisabled && hasApprovalRights && (
        <>
          {getPreviewComment() && (
            <div className={styles.previewCommentSection}>
              <Text type="secondary" style={{ textTransform: 'uppercase' }}>
                {translation('preview_comments')}
              </Text>
              <br />
              <Text>{renderPreviewComment()}</Text>
            </div>
          )}
          <div className={styles.textArea}>
            {approvalType === 'SingleApprover' && (
              <Alert
                className={styles.twoEyesInfo}
                message={
                  <Text style={{ fontSize: '12px' }} type="secondary">
                    {translation('two_eyes_info')}
                  </Text>
                }
                type="info"
                showIcon
              />
            )}
            <Space
              className={
                workTypeLoadingState ? styles.loadingBanner : styles.hideDisplay
              }
            >
              <Spin size="large" spinning={workTypeLoadingState} />
            </Space>
            <TextArea
              value={comment}
              disabled={disableActions || isCommentDisabled}
              rows={4}
              maxLength={600}
              allowClear
              onChange={(event) => setComment(event.currentTarget.value)}
              placeholder={`${intl.formatMessage({
                id: 'additional_comments',
              })} - ${intl.formatMessage({ id: 'comment_placeholder' })}`}
            />
            {renderCommentImageThumbnails()}
          </div>

          <div className={styles.reasonCodeBox}>
            <Select
              mode="multiple"
              allowClear
              style={{ width: '100%' }}
              placeholder={`${intl.formatMessage({
                id: 'reason_code_label',
              })} - ${intl.formatMessage({ id: 'reason_code_placeholder' })}`}
              value={selectedReasonCodes.map(
                (reason: IReasonCode) => reason.id
              )}
              onChange={handleChange}
              optionLabelProp="label"
              disabled={disableActions || isCommentDisabled}
              filterOption={(input: String, option: any) =>
                option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              tagRender={tagRender}
            >
              {reasonCodes.map((reason: IReasonCode) => (
                <Option
                  key={reason.id}
                  label={`${reason.code} ${reason.description}`}
                  value={reason.id}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      flexWrap: 'nowrap',
                    }}
                  >
                    <div style={{ whiteSpace: 'pre-wrap' }}>
                      {reason.description}
                    </div>
                  </div>
                  <div>
                    <Badge
                      status={
                        reason.severity === ReasonCodeType.blocker
                          ? 'error'
                          : 'warning'
                      }
                    />
                    <Text type="secondary">{reason.code}</Text>
                  </div>
                  <Divider style={{ margin: '0' }} />
                </Option>
              ))}
            </Select>
          </div>
        </>
      )}

      {isCommentDisabled && (
        <div className={styles.previewCommentSection}>
          <Text>{translation('approver_label')}</Text>
          <div>
            <Text type="secondary">{translation('approver_desc_2')}</Text>
          </div>
        </div>
      )}

      <div className={styles.buttons}>
        {hasApprovalRights &&
          (showSteps ? (
            <>
              <Button
                className={styles.statusApproved}
                shape="round"
                disabled={
                  disableActions ||
                  workTypeLoadingState ||
                  isCommentDisabled ||
                  !!selectedReasonCodes.find(
                    (reason: IReasonCode) =>
                      reason.severity === ReasonCodeType.blocker
                  )
                }
                icon={
                  <span style={{ padding: '5px 6px 0 0' }}>
                    <PlaySvg />
                  </span>
                }
                type="primary"
                onClick={() => {
                  onSubmitComment(
                    fieldId,
                    getPreviewComment() || 'Approved',
                    true,
                    appointmentId
                  );
                  setImageSubmitSuccess(false);
                  handleGoToNextStep(undefined);
                }}
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {translation('btn_approve')}
              </Button>
              <Button
                className={styles.statusRejected}
                shape="round"
                disabled={
                  disableActions || isCommentDisabled || workTypeLoadingState
                }
                icon={<CloseOutlined />}
                onClick={() => {
                  if (
                    commentsToShow[commentsToShow.length - 1]?.approval ===
                    'Approved'
                  ) {
                    let postCommentText = getPreviewComment();
                    postCommentText.length
                      ? setIsConfirmationModalVisible(true)
                      : setIsModalVisible(true);
                  } else {
                    let postCommentText = getPreviewComment();
                    if (postCommentText.length) {
                      onSubmitComment(
                        fieldId,
                        postCommentText,
                        false,
                        appointmentId
                      );
                      setNumberOfSteps(2);
                      handleGoToNextStep(3);
                    } else {
                      setIsModalVisible(true);
                    }
                  }
                }}
              >
                <span>{translation('btn_reject')}</span>
              </Button>
            </>
          ) : (
            <>
              <Button
                className={styles.statusApproved}
                shape="round"
                disabled={
                  disableActions ||
                  workTypeLoadingState ||
                  isCommentDisabled ||
                  !!selectedReasonCodes.find(
                    (reason: IReasonCode) =>
                      reason.severity === ReasonCodeType.blocker
                  )
                }
                icon={<CheckOutlined />}
                type="primary"
                onClick={() =>
                  onSubmitComment(
                    fieldId,
                    getPreviewComment() || 'Approved',
                    true,
                    appointmentId
                  )
                }
              >
                <span>{translation('btn_approve')}</span>
              </Button>
              <Button
                className={styles.statusRejected}
                shape="round"
                disabled={
                  disableActions || isCommentDisabled || workTypeLoadingState
                }
                icon={<CloseOutlined />}
                onClick={() => {
                  if (
                    commentsToShow[commentsToShow.length - 1]?.approval ===
                    'Approved'
                  ) {
                    let postCommentText = getPreviewComment();
                    postCommentText.length
                      ? setIsConfirmationModalVisible(true)
                      : setIsModalVisible(true);
                  } else {
                    let postCommentText = getPreviewComment();
                    postCommentText.length
                      ? onSubmitComment(
                          fieldId,
                          postCommentText,
                          false,
                          appointmentId
                        )
                      : setIsModalVisible(true);
                  }
                }}
              >
                <span>{translation('btn_reject')}</span>
              </Button>
            </>
          ))}

        <Button
          className={styles.btnEscalate}
          shape="round"
          onClick={async () => {
            await window.navigator.clipboard.writeText(clipboardText);
            showNotificationMessage({
              message: '',
              description: intl.formatMessage({ id: 'escalate_notify' }),
              notificationType: NotificationType.info,
              duration: 2,
            });
          }}
        >
          <span>{translation('btn_escalate')}</span>
        </Button>
      </div>

      <Modal
        title={translation('warning')}
        onOk={() => setIsModalVisible(false)}
        onCancel={() => {
          setIsModalVisible(false);
        }}
        open={isModalVisible}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <p>{translation('comment_modal_visible_text')}</p>
      </Modal>

      <Modal
        title={translation('warning')}
        onOk={() => {
          setIsConfirmationModalVisible(false);
          onSubmitComment(fieldId, getPreviewComment(), false, appointmentId);
        }}
        open={isModalConfirmationVisible}
        onCancel={() => {
          setIsConfirmationModalVisible(false);
        }}
        okText="Reject"
        okType="primary"
        cancelText="Cancel"
      >
        <p>{translation('comment_modal_confirmation_visible_title')}</p>
        <p>{translation('comment_modal_confirmation_visible_text')}</p>
      </Modal>
    </>
  );

  const renderApproverSection = () =>
    hasApprovalRights && (
      <>
        <div className={styles.previewCommentSection}>
          <Text>{translation('approver_label')}</Text>
          <div>
            <Text type="secondary">{translation('approver_desc')}</Text>
          </div>
        </div>
        <div className={styles.buttons}>
          <Button
            className={styles.statusConfirm}
            shape="round"
            disabled={disableActions || isProcessingApi}
            icon={<CheckOutlined />}
            type="primary"
            onClick={() => {
              setIsProcessingApi(true);
              dispatch(
                WorkTypeAction.confirmReviewComment(
                  fieldId,
                  appointmentId,
                  answerSnapshot?.id ?? ''
                )
              );
              handleGoToNextStep(undefined);
              setImageSubmitSuccess(false);
            }}
          >
            <span>{translation('btn_confirm')}</span>
          </Button>
          <Button
            className={styles.statusDeny}
            shape="round"
            disabled={disableActions}
            icon={<CloseOutlined />}
            onClick={() => {
              setDenyModalVisible(true);
            }}
          >
            <span>{translation('btn_deny')}</span>
          </Button>
          <Button
            className={styles.btnEscalate}
            shape="round"
            onClick={async () => {
              await window.navigator.clipboard.writeText(clipboardText);
              showNotificationMessage({
                message: '',
                description: intl.formatMessage({ id: 'escalate_notify' }),
                notificationType: NotificationType.info,
                duration: 2,
              });
            }}
          >
            <span>{translation('btn_escalate')}</span>
          </Button>
        </div>
        <Modal
          title={translation('deny_modal_title')}
          onOk={() => {
            setDenyModalVisible(false);
            //setApproverMode(false);
            setIsOverwritingApproval(true);
          }}
          open={isDenyModalVisible}
          onCancel={() => {
            setDenyModalVisible(false);
          }}
          okText={translation('btn_deny')}
          okType="primary"
          cancelText={translation('cancel')}
        >
          <h3>{translation('deny_modal_text')}</h3>
          <Text type="secondary">{translation('deny_modal_desc')}</Text>
        </Modal>
      </>
    );

  const renderReopenCommentSection = () => (
    <>
      <AccessControl featureKey={IFeatures.fieldDetailComment}>
        <div className={styles.textArea}>
          <TextArea
            value={comment}
            rows={4}
            disabled={appointment?.status === 'Closed'}
            maxLength={600}
            allowClear
            onChange={(event) => setComment(event.currentTarget.value)}
            placeholder={intl.formatMessage({ id: 'comment_placeholder' })}
          />
          {renderCommentImageThumbnails()}
        </div>
      </AccessControl>
      <div className={styles.buttons}>
        <AccessControl featureKey={IFeatures.fieldDetailComment}>
          <Button
            className={styles.statusComment}
            shape="round"
            disabled={appointment?.status === 'Closed'}
            loading={workTypeLoadingState}
            icon={<CommentOutlined />}
            onClick={() => onSubmitReply()}
          >
            <span>{translation('btn_comment')}</span>
          </Button>
        </AccessControl>
        <AccessControl featureKey={IFeatures.fieldDetailReopen}>
          <Button
            className={styles.btnReopen}
            shape="round"
            icon={<ReloadOutlined />}
            disabled={appointment?.status === 'Closed' || workTypeLoadingState}
            onClick={() => {
              setImageSubmitSuccess(undefined);
              setReopenModalVisible(true);
            }}
          >
            <span>{translation('btn_reopen')}</span>
          </Button>
        </AccessControl>
        <Button
          className={styles.btnEscalate}
          shape="round"
          onClick={async () => {
            await window.navigator.clipboard.writeText(clipboardText);
            showNotificationMessage({
              message: '',
              description: intl.formatMessage({ id: 'escalate_notify' }),
              notificationType: NotificationType.info,
              duration: 2,
            });
          }}
        >
          <span>{translation('btn_escalate')}</span>
        </Button>
      </div>

      <Modal
        title={translation('reopen_modal_title')}
        onOk={() => {
          setReopenModalVisible(false);
          setComment('');
          dispatch(
            WorkTypeAction.reopen(
              fieldId,
              appointmentId,
              answerSnapshot?.id ?? ''
            )
          );
          //if reopened, we need to go to 1st step
          if (showSteps) {
            setNumberOfSteps(3);
            handleGoToNextStep(1);
          }
        }}
        open={isReopenModalVisible}
        onCancel={() => {
          setReopenModalVisible(false);
        }}
        okText={translation('btn_reopen')}
        okType="primary"
        cancelText={translation('cancel')}
      >
        <h3>{translation('deny_modal_text')}</h3>
        <Text>
          {translation(
            approvalType === 'SingleApprover'
              ? 'reopen_modal_2_desc'
              : 'reopen_modal_desc'
          )}
        </Text>
      </Modal>
    </>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.comments}>
        {commentsToShow.map((comment, index) => (
          <Comment
            key={`comment-item-${index}`}
            {...comment}
            isHighlighted={questions && comment.actor === 'User'}
            setSelectedImageUrl={setSelectedImageUrl}
            isEditable={
              fieldStatus === 'Rejected' ||
              fieldStatus === 'Approved' ||
              !isApproverMode ||
              isOverwritingApproval
            }
          />
        ))}
        <span ref={listEnd} />
      </div>
      {isActiveSubmission && (
        <>
          <Divider style={{ margin: '0' }} />
          {fieldStatus === 'Rejected' || fieldStatus === 'Approved'
            ? renderReopenCommentSection()
            : isApproverMode && !isOverwritingApproval
            ? renderApproverSection()
            : renderCommentSection()}
        </>
      )}
      {selectedImageUrl &&
        (((fieldStatus === 'Rejected' || fieldStatus === 'Approved') &&
          privileges[IFeatures.fieldDetailComment]) ||
          (fieldStatus !== 'Rejected' &&
            fieldStatus !== 'Approved' &&
            hasApprovalRights)) && (
          <ImageEdit
            src={selectedImageUrl}
            okText={intl.formatMessage({
              id: 'attach_to_comment',
            })}
            isDisabled={
              commentImageList.length < 5
                ? false
                : commentImageList.indexOf(selectedImageUrl) !== -1
                ? false
                : true
            }
            isVisible={!!selectedImageUrl}
            onSelect={onUpdateCommentImageList}
            onClose={() => setSelectedImageUrl('')}
          />
        )}
    </div>
  );
};

export default Comments;
